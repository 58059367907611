<template>
	<div id="idnex">
		<common-header></common-header>
		<div class="index-wrap">
			<div class="banner">
				<img src="http://cdn.yunhjc.com/home_banner.png" alt="" />
				<div class="content_container">
					<div class="guide">
						<h1>时间+事务,轻重缓急就够了</h1>
						<h3>全流程协作，事务、记录，轻重缓急来解决</h3>
						<h3>用工具来完成的事务吧！</h3>
						<div class="experience" @click="use">免费体验</div>
					</div>
				</div>
			</div>
			<section class="describe">
				<h1>我的时间管理，时间管理事务平台</h1>
				<div class="describe-group">
					<div class="describe-item">
						<div class="font">
							<i class="iconfont iconinallhaste"></i>
						</div>
						<h3>事务添加快速上手</h3>
						<p>
							添加事务和任务，拖拽即可分类。快速添加、快速完成、多种展示预览方式。轻重缓急让事务完成如此简单
						</p>
					</div>
					<div class="describe-item">
						<div class="font">
							<i class="iconfont iconefficiency"></i>
						</div>
						<h3>协同工作效率提升</h3>
						<p>
							从很重要-很紧急、很重要-不紧急到不重要-很紧急，不重要-不紧急，一起协同工作，支持主流格式的事务和任务完成。轻重时间管理工具，让每个人更快，让团队效能更高。
						</p>
					</div>
					<div class="describe-item">
						<div class="font">
							<i class="iconfont iconAdministration"></i>
						</div>
						<h3>事务添加快速上手</h3>
						<p>
							轻松管理事务、统一管理事务任务，还可以一键导出，轻重缓急，让时间管理的价值得以真正体现。
						</p>
					</div>
				</div>
			</section>
			<!--            -->
			<section class="down-app">
				<div class="down-app-title">
					手机App下载
					<span class="border-bot"></span>
				</div>
				<div class="phone-qr">
					<div class="phone">
						<img src="http://cdn.yunhjc.com/phone.png" alt="" />
					</div>
					<div class="down-des">
						<div class="describe-qr">
							<h4>轻重时间管理工具</h4>
							<p>
								记录下来需要做的一些事物，根据事物的重要性来进行等级的划分，让自己
								的事物更加有规划的去完成，根据事物的紧急、不紧急的进行规划管理（把
								所有能想到的事物，全部写上去，平时花了时间的东西）
							</p>
							<div class="android"><i></i>Android</div>
							<div class="qr">
								<img src="~assets/images/app.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="bottom-start">
				<div>
					<h3>大家都在用轻重缓急时间管理创造更多时间</h3>
					<p>相信你和团队也可以</p>
					<div class="start" @click="use">免费开始</div>
				</div>
			</section>
			<footer class="footer">
				<div class="row">
					<table>
						<tr>
							<td>友情链接</td>
						</tr>
						<tr>
							<td>天天安居</td>
							<td>收藏夹</td>
							<td>挣外快</td>
							<td>百度</td>
						</tr>
						<tr>
							<td>阿里云</td>
							<td>腾讯</td>
							<td>简书</td>
							<td>码云</td>
						</tr>
					</table>

					<table>
						<tr>
							<td>联系我们</td>
						</tr>
						<tr>
							<td>电话：13699820382(微信同号)</td>
						</tr>
						<tr>
							<td>邮箱： yunhjc@163.com</td>
						</tr>
						<tr>
							<td>公司： 深圳市云海竟成科技有限公司</td>
						</tr>
					</table>
				</div>
				<div class="copyright">
					©2020-2021深圳市云海竟成科技有限公司All Rights Reserved
					<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备15072947号-6 </a>
				</div>
			</footer>
		</div>
		<!--        返回顶部-->
		<div
			class="back-top fadeIn-enter"
			v-show="showBackTop"
			@click="backTop"
		>
			<i class="iconfont iconBacktothetop"></i>
		</div>
	</div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader.vue'
export default {
	name: 'Index',
	data() {
		return {
			showBackTop: false, //显示回到顶部按钮
		}
	},
	components: { CommonHeader },
	mounted() {
		window.addEventListener(
			'scroll',
			() => (this.showBackTop = this.getScrollTop() >= 50)
		)
	},
	methods: {
		getScrollTop() {
			let scrollTop = 0
			if (
				document.documentElement &&
				document.documentElement.scrollTop
			) {
				scrollTop = document.documentElement.scrollTop
			} else if (document.body) {
				scrollTop = document.body.scrollTop
			}
			return scrollTop
		},
		backTop() {
			let el = document.body || document.documentElement
			el.scrollIntoView({ behavior: 'smooth' })
		},
		// 免费使用
		use() {
			if (localStorage.j_token) {
				this.$router.push('home')
			} else {
				this.$router.push('login')
			}
		},
	},
}
</script>

<style lang="less" scoped>
.fadeIn-enter {
	-webkit-animation: fadeInWrapEnter 0.4s;
	animation: fadeInWrapEnter 0.3s;
}

@keyframes fadeInWrapEnter {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

#idnex {
	min-width: 1200px;
	.index-wrap {
		.banner {
			width: 100%;
			max-height: calc(100vh - 50px);
			position: relative;
			overflow: hidden;
			img {
				width: 100%;
				min-width: 1200px;
				z-index: -1;
			}
			.content_container {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				text-align: center;
				position: absolute;
				top: 0;
				h1 {
					padding-bottom: 30px;
					font-weight: initial;
					font-size: 40px;
				}
				h3 {
					font-weight: initial;
				}
				.experience {
					width: 200px;
					height: 46px;
					background: #70ccff;
					box-shadow: 0px 0px 10px #70ccff;
					border-radius: 4px;
					text-align: center;
					line-height: 46px;
					color: #fff;
					margin: 50px auto;
					cursor: pointer;
				}
			}
		}
		/*    */
		.describe {
			width: 100%;
			margin: 140px 0;
			h1 {
				text-align: center;
				font-size: 22px;
				color: #000000;
			}
			.describe-group {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 120px;
				.describe-item {
					width: 400px;
					height: 300px;
					text-align: center;
					.iconfont {
						font-size: 58px;
						color: #70ccff;
					}
					h3 {
						margin: 30px 0;
					}
					p {
						line-height: 30px;
						/*text-align: left;*/
						text-align: justify;
					}
				}
				.describe-item:nth-of-type(2) {
					margin: 0 130px;
				}
			}
		}
		/*下载app*/
		.down-app {
			margin: 140px 0;
			.down-app-title {
				text-align: center;
				font-weight: bold;
				font-size: 18px;
				.border-bot {
					display: block;
					width: 150px;
					height: 10px;
					background: #70ccff;
					text-align: center;
					margin: auto;
					position: relative;
					bottom: 6px;
					z-index: -1;
				}
			}
			.phone-qr {
				display: flex;
				justify-content: center;
				.phone {
					width: 594px;
					img {
						width: 100%;
					}
				}
				.down-des {
					width: 560px;
					position: relative;
					margin-left: 40px;
				}
				.describe-qr {
					position: absolute;
					bottom: 0;
					h4 {
						font-size: 18px;
						font-weight: normal;
						color: #70ccff;
					}
					p {
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 25px;
						color: #333333;
						margin: 10px 0 80px 0;
					}
					.android {
						width: 180px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						background: #70ccff;
						border-radius: 30px 30px 0px 30px;
						color: #fff;
					}
					.qr {
						width: 220px;
						height: 220px;
						border: 1px solid #70ccff;
						box-sizing: border-box;
						border-radius: 10px;
						margin-top: 20px;
						overflow: hidden;
						img {
							width: 100%;
							display: block;
						}
					}
				}
			}
		}
		/*    免费开始*/
		.bottom-start {
			width: 100%;
			height: 280px;
			text-align: center;
			background: #70ccff;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			h3 {
				font-size: 26px;
			}
			p {
				font-size: 20px;
				padding: 20px 0;
			}
			.start {
				width: 180px;
				height: 40px;
				background: #fff;
				color: #70ccff;
				margin: auto;
				text-align: center;
				line-height: 40px;
				cursor: pointer;
				border-radius: 4px;
			}
		}
		.footer {
			width: 100%;
			background: #141a25;
			color: #fff;

			.row {
				width: 1200px;
				margin: auto;
				display: flex;
				box-sizing: border-box;
				padding: 20px 0 58px 0;
				border-bottom: 1px solid #eee;
				font-size: 14px;
				table {
					margin-right: 150px;
					td {
						padding: 4px;
					}
				}
				table:last-of-type {
					margin: 0;
				}
			}
			.copyright {
				box-sizing: border-box;
				text-align: center;
				padding: 30px 0;
				font-size: 14px;
                a {
                    color: white;
                }
			}
		}
	}
	/*    返回顶部*/
	.back-top {
		width: 38px;
		height: 38px;
		border-radius: 4px;
		background: #eee;
		text-align: center;
		line-height: 38px;
		position: fixed;
		bottom: 25px;
		right: 25px;
		cursor: pointer;
		.iconfont {
			font-size: 22px;
			color: #333333;
		}
	}
}
</style>
